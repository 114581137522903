import {
  Box,
  Flex,
  HStack,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { Logo } from '../Logo'
import { MobileNav } from './MobileNav'
import { NavLink } from './NavLink'

export const App = () => {
  return (
    <Box as="header" bg={mode('white', 'gray.800')} borderBottomWidth="1px">
      <Box maxW="7xl" mx="auto" py="4" px={{ base: '6', md: '8' }}>
        <Flex as="nav" justify="space-between">
          <HStack spacing="8">
            <Box as="a" href="#" rel="home">
              <Logo />
            </Box>
          </HStack>
          <Flex align="center">
            <HStack display={{ base: 'none', lg: 'flex' }} spacing="8">
              <NavLink.Desktop href="#servicios">Servicios</NavLink.Desktop>
              <NavLink.Desktop href="#tecnologias">Tecnologías</NavLink.Desktop>
              <NavLink.Desktop href="#contactanos">Contáctanos</NavLink.Desktop>
            </HStack>
            <Box ml="5">
              <MobileNav />
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}

import { Box, SimpleGrid, Heading } from '@chakra-ui/react'
import * as React from 'react'
import {
  FcMultipleDevices,
  FcSelfie,
  FcStatistics,
  FcAreaChart,
  FcElectronics,
  FcCandleSticks,
} from 'react-icons/fc'
import { Feature } from './Feature'

export const App = () => (
  <Box id="servicios" as="section" maxW="5xl" mx="auto" py="12" px={{ base: '6', md: '8' }}>
    <Heading align="center" fontWeight="extrabold" maxW="md" mx="auto">
      Nuestros Servicios
    </Heading>
    <SimpleGrid columns={{ base: 1, md: 2 }} mt="8" spacingX="10" spacingY={{ base: '8', md: '14' }}>
      <Feature title="Análisis de Datos" icon={<FcStatistics />}>
        Recolectamos datos de distintas fuentes, los limpiamos y ordenamos para poder utilizarlos en la toma de decisiones y predicción de nuevos datos.
      </Feature>
      <Feature title="Visualización de Datos" icon={<FcAreaChart />}>
        Generamos visualizaciones interactivas que ayudan a nuestros clientes a interpretar de mejor manera sus datos y tomar mejores desiciones.
      </Feature>
      <Feature title="IoT" icon={<FcElectronics />}>
        Nos integramos con distintos dispositivos electrónicos para la extracción de datos en tiempo real.
      </Feature>
      <Feature title="Computer Vision" icon={<FcSelfie />}>
        Reconocemos distintos objetos y patrones en imagenes, audio, texto y video.
      </Feature>
      <Feature title="Módelos Predictivos" icon={<FcCandleSticks />}>
        Una vez obtenidos los datos generamos un módelo de inteligencia artificial que resuelve el problema de nuestros clientes.
      </Feature>
      <Feature title="Desarrollo de Software Múltiplataforma" icon={<FcMultipleDevices />}>
        Creamos sistemas web y móviles que utilizan los módelos de inteligencia artificial para obtener increíbles experiencias de usuario.
      </Feature>
    </SimpleGrid>
  </Box>
)

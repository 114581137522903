import React from 'react';
import { WithBoxedLogo } from './components/WithBoxedLogo'
import { ThreeColumnGridBelow } from './components/ThreeColumnGridBelow'
import { TwoByTwoFeatures } from './components/TwoByTwoFeatures'
import { Form } from './components/Form'
import { Navbar } from './components/Navbar'
import { Footer } from './components/Footer'

function App() {
  return (
    <div>
      <Navbar />
      <ThreeColumnGridBelow />
      <TwoByTwoFeatures />
      <WithBoxedLogo />
      <Form />
      <Footer />
    </div>
  );
}

export default App;

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Stack,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import * as React from 'react'

export const App = () => (
  <Box id="contactanos" as="section" maxW="3xl" mx="auto" py="12" px={{ base: '6', md: '8' }}>
    <form
      id="settings-form"
      onSubmit={(e) => {
        e.preventDefault()
        // form submit logic
      }}
    >
      <Heading align="center" fontWeight="extrabold" maxW="md" mx="auto">
        Contáctanos
      </Heading>
      <Stack mt="8">
        <VStack width="full">
          <FormControl id="name">
            <FormLabel>Nombre</FormLabel>
            <Input type="text" maxLength={255} />
          </FormControl>

          <FormControl id="email">
            <FormLabel>Correo electrónico</FormLabel>
            <Input type="email" />
          </FormControl>

          <FormControl id="message">
            <FormLabel>Mensaje</FormLabel>
            <Textarea rows={5} />
            <FormHelperText>
              Cuentanos que proyecto tienes en mente
            </FormHelperText>
          </FormControl>
        </VStack>
      </Stack>
      <Stack mt="8">
        <Button  size="lg" type="submit" colorScheme="blue">
          Enviar
        </Button>
      </Stack>
    </form>
  </Box>
)

import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import App from './App';
import reportWebVitals from './reportWebVitals';

const theme = extendTheme({
  styles: {
    global: {
      "html": {
        'scroll-behavior': 'smooth'
      },
    },
  },
  colors: {
    "purple": {
        "50": "#EEEFF6",
        "100": "#D0D1E6",
        "200": "#B2B3D7",
        "300": "#9496C7",
        "400": "#7678B7",
        "500": "#585BA7",
        "600": "#464886",
        "700": "#353664",
        "800": "#232443",
        "900": "#121221"
      },
      "blue": {
        "50": "#EDF3F7",
        "100": "#CEDEE9",
        "200": "#AEC8DB",
        "300": "#8FB3CC",
        "400": "#6F9EBE",
        "500": "#4F88B0",
        "600": "#3F6D8D",
        "700": "#305269",
        "800": "#203646",
        "900": "#101B23"
      },
      "gray": {
        "50": "#EFF0F5",
        "100": "#D2D6E4",
        "200": "#B5BBD3",
        "300": "#99A1C2",
        "400": "#7C87B1",
        "500": "#5F6CA0",
        "600": "#4C5680",
        "700": "#394160",
        "800": "#262B40",
        "900": "#131620"
      },
      "cyan": {
        "50": "#EDF1F8",
        "100": "#CBD8EB",
        "200": "#AABFDE",
        "300": "#89A6D2",
        "400": "#688CC5",
        "500": "#4773B8",
        "600": "#395C93",
        "700": "#2B456E",
        "800": "#1C2E4A",
        "900": "#0E1725"
      }
  },
})

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Box, Center, Heading, SimpleGrid, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'

import hadoop from './imgs/hadoop_byw.png'
import jupyter from './imgs/jupyter_byw.png'
import python from './imgs/python_byw.png'
import pytorch from './imgs/pytorch_byw.png'
import pyspark from './imgs/pyspark_byw.png'
import opencv from './imgs/opencv_byw.png'

export const App = () => {
  return (
    <Box id="tecnologias" as="section" py="16">
      <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
        <Heading align="center" fontWeight="extrabold" maxW="md" mx="auto">
          Nuestras Herramientas Tecnológicas
        </Heading>
        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 3 }}
          mt="8"
          spacing="6"
          color={mode('inherit', 'white')}
        >
          <Center py="8" px="8" bg={mode('gray.50', 'gray.800')} rounded={{ md: 'lg' }}>
            <img src={python} width={180} alt='Logo Python' />
          </Center>
          <Center py="8" px="8" bg={mode('gray.50', 'gray.800')} rounded={{ md: 'lg' }}>
            <img src={jupyter} width={150} alt='Logo Jupyter' />
          </Center>
          <Center py="8" px="8" bg={mode('gray.50', 'gray.800')} rounded={{ md: 'lg' }}>
            <img src={pytorch} width={150} alt='Logo Pytorch' />
          </Center>
          <Center py="8" px="8" bg={mode('gray.50', 'gray.800')} rounded={{ md: 'lg' }}>
            <img src={opencv} width={150} alt='Logo Opencv' />
          </Center>
          <Center py="8" px="8" bg={mode('gray.50', 'gray.800')} rounded={{ md: 'lg' }}>
            <img src={pyspark} width={150} alt='Logo Pyspark' />
          </Center>
          <Center py="8" px="8" bg={mode('gray.50', 'gray.800')} rounded={{ md: 'lg' }}>
            <img src={hadoop} width={150} alt='Logo Hadoop' />
          </Center>
        </SimpleGrid>
      </Box>
    </Box>
  )
}
